@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.spin {
    animation: spin 40s linear infinite;
}